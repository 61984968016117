$ROW_HEIGHT: 145px;
$COLUMN_HEIGHT: 80VW;
.container{
  img {
    border-radius: 5px;
    object-fit: cover;
  }
}
.one_row{
  height: $ROW_HEIGHT;
  width: 100%;
  margin-top: 10px;
}

.one_column{
  width: 100%;
  margin-top: 10px;
  // height: $COLUMN_HEIGHT;
  height: $ROW_HEIGHT;
}
.two_row{
  img {
    height: $ROW_HEIGHT;
    width: 100%;
    margin-top: 10px;
  }
}
.two_column{
  margin-top: 10px;
  img {
    width: 48%;
    height: $COLUMN_HEIGHT;
  }
}
.three_row {
  :nth-child(1) {
    margin-top: 5px; 
    img {
      height: $ROW_HEIGHT;
      width: 100%;
    }
  }
  :nth-child(2) {
    img {
      width: 48.5%;
      height:calc(48.5vw - 10px);
    }
  } 
}
.three_column{
  height: $COLUMN_HEIGHT;
  :nth-child(1) {
    img{
      width: 48%;
      height: $COLUMN_HEIGHT;
    }
    div {
      width: 50%;
      height: $COLUMN_HEIGHT;
      img{
        height: 40vw;
        width: 100%;
      }
    }
  }
}

.four_column{
  margin-top: 10px;
  height: 50vw;
  :nth-child(1) {
    img{
      width: 66%;
      height: 50vw;
    }
    div {
      width: 35%;
      height: 50vw;
      img{
        padding-left: 10px;
        height: 15.5vw;
        width: 100%;
      }
    }
  }
}