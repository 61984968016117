@import "./variable.scss";
html,
body {
  height: 100%;
  background-color: #fff;
  width: 100%;
  overflow: hidden;
  -webkit-overflow-scrolling: touch;
  font-family: "PingFang SC", "Helvetica Neue", "Microsoft Yahei", Tahoma,
    sans-serif;
  // touch-action: none; 
}
#root {
  height: 100%;
  max-width: 740px;
  margin: 0 auto;
}
#app {
  max-width: 740px;
  margin: 0 auto;
  background-color: #fff;
  position: relative;
  height: 100%;
  width: 100%;
  top: 0px;
  > div {
    height: 100%;
  }
}
.container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  width: 100%;
  // 全面屏适配
  // padding-bottom: 15px;
}
.page {
  height: 100%;
  width: 100%;
  padding-top: 45px;
  overflow-y: auto;
}
.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.text-center {
  text-align: center;
}

.text-overflow {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.bg-white {
  background: #fff;
}

.bg-33 {
  background: #333;
}

.bg-56 {
  background: #ff004e;
}

.bg-48 {
  background-color: #484d59;
}
.bg-4b {
  background-color: #4b832a;
}
.bg-7a {
  background-color: #7aa9f8 !important;
}
.bg-f8 {
  background: #f8f8f8;
}
.bg-f9 {
  background: #f9f9f9;
}

.bg-hui {
  background: #eee;
}

.bg-red {
  background: #dd2727;
}

.bg-green {
  background: #02bf02;
}

.bg-cheng {
  background: #f85;
}
.bg-ef {
  background: #efefef;
}
.bg-primary {
  background: $color-primary;
}
.clear {
  clear: both;
}
.red {
  color: #fe5656;
}
.blue {
  color: #60adf7;
}
.c0 {
  color: #000;
}

.c3 {
  color: #333;
}

.c6 {
  color: #666;
}

.c9 {
  color: #999;
}

.c56 {
  color: #fe5656 !important;
}
.color-ab {
  color: #ababab;
}
.color-b1 {
  color: #b1b1b1;
}
.color-4b {
  color: #4b832a;
}

.green {
  color: #67c774;
}

.color-primary {
  color: $color-primary;
}
.color-blue {
  color: $color-blue;
}
.white {
  color: #fff;
}

.font7 {
  font-size: 7px;
}

.font8 {
  font-size: 8px;
}

.font9 {
  font-size: 9px;
}

.font10 {
  font-size: $font10;
}

.font11 {
  font-size: $font11;
}

.font12 {
  font-size: $font12;
}

.font13 {
  font-size: $font13;
}

.font14 {
  font-size: $font14;
}

.font15 {
  font-size: $font15;
}

.font16 {
  font-size: $font16;
}

.font17 {
  font-size: $font17;
}

.font18 {
  font-size: $font18;
}

.font19 {
  font-size: $font19;
}

.font20 {
  font-size: $font20;
}
.font22 {
  font-size: $font22;
}

.font28 {
  font-size: 28px;
}

.font30 {
  font-size: 30px;
}
.font35 {
  font-size: 35px;
}

.l-h20 {
  line-height: 20px;
}

/*定位*/

.relative {
  position: relative;
}

.absolute {
  position: absolute;
}

/*文字超出省略*/

.ellipsis {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  display: block;
}

/*对齐*/

.tl {
  text-align: left;
}

.tc {
  text-align: center;
}

.tr {
  text-align: right;
}

/*间距*/
.ml2 {
  margin-left: 2px;
}
.mt2 {
  margin-top: 2px;
}
.mb2 {
  margin-bottom: 2px;
}
.mt3 {
  margin-top: 3px;
}
.pr5 {
  padding-right: 5px;
}
.pl5 {
  padding-left: 5px;
}
.pt5 {
  padding-top: 5px;
}
.pb5 {
  padding-bottom: 5px;
}
.ml5 {
  margin-left: 5px;
}
.mr5 {
  margin-right: 5px;
}
.mt5 {
  margin-top: 5px;
}
.mb5 {
  margin-bottom: 5px;
}
.ml7 {
  margin-left: 7px;
}
.mr7 {
  margin-right: 7px;
}
.mt7 {
  margin-top: 7px;
}
.mb7 {
  margin-bottom: 7px;
}
.mt10 {
  margin-top: 10px;
}
.mb10 {
  margin-bottom: 10px;
}
.mr10 {
  margin-right: 10px;
}
.ml10 {
  margin-left: 10px;
}
.mt12 {
  margin-top: 12px;
}
.mb12 {
  margin-bottom: 12px;
}
.mr12 {
  margin-right: 12px;
}
.ml12 {
  margin-left: 12px;
}
.mtpx10 {
  margin-top: 10px;
}
.mt15 {
  margin-top: 15px;
}
.ml15 {
  margin-left: 15px;
}
.mb15 {
  margin-bottom: 15px;
}
.mr15 {
  margin-right: 15px;
}
.mt20 {
  margin-top: 20px;
}
.ml20 {
  margin-left: 20px;
}
.mb20 {
  margin-bottom: 20px;
}
.mr20 {
  margin-right: 20px;
}
.mb30 {
  margin-bottom: 30px;
}
.mt30 {
  margin-top: 30px;
}
.p0 {
  padding: 0 !important;
}
.pt10 {
  padding-top: 10px;
}
.pl10 {
  padding-left: 10px;
}
.pr10 {
  padding-right: 10px;
}
.pb10 {
  padding-bottom: 10px;
}
.pt15 {
  padding-top: 15px;
}
.pl15 {
  padding-left: 15px;
}
.pr15 {
  padding-right: 15px;
}
.pb15 {
  padding-bottom: 15px;
}
.pt20 {
  padding-top: 20px;
}
.pl20 {
  padding-left: 20px;
}
.pr20 {
  padding-right: 20px;
}
.pb20 {
  padding-bottom: 20px;
}
.pt40 {
  padding-top: 40px;
}
.mt40 {
  margin-top: 40px;
}
.br25 {
  border-radius: 25px !important;
}
.br5 {
  border-radius: 5px;
}
.p10 {
  padding: 10px;
}

.mb1-line {
  margin-bottom: 1px solid rgba(29, 29, 38, 0.1);
}

.p-all {
  padding: 3%;
}

/*边框*/

.bte {
  border-top: solid 1px #eee;
}

.bbe {
  border-bottom: solid 1px #eee;
}

.bre {
  border-right: 1px solid #eee;
}

.bt5 {
  border-top: 5px solid rgba(0, 0, 0, 0.05);
}

.bt10 {
  border-top: 10px solid rgba(0, 0, 0, 0.05);
}

.bt1 {
  border-top: 1px solid rgba(29, 29, 38, 0.1);
}

.bb1 {
  border-bottom: 1px solid rgba(29, 29, 38, 0.1);
}

/*浮动*/

.fl-l {
  float: left;
}

.fl-r {
  float: right;
}

.w8 {
  width: 8%;
}

.w10 {
  width: 10%;
}
.w15 {
  width: 15%;
}

.w20 {
  width: 20%;
}

.w25 {
  width: 25%;
}

.w30 {
  width: 30%;
}
.w33 {
  width: 33%;
}

.w40 {
  width: 40%;
}
.w45 {
  width: 45%;
}

.w50 {
  width: 50%;
}

.w60 {
  width: 60%;
}
.w65 {
  width: 65%;
}

.w70 {
  width: 70%;
}
.w75 {
  width: 75%;
}

.w80 {
  width: 80%;
}
.w85 {
  width: 85%;
}

.w90 {
  width: 90%;
}

.w100 {
  width: 100%;
}
.w400 {
  font-weight: 400;
}
.w500 {
  font-weight: 500;
}
.w600 {
  font-weight: 600;
}
.w700 {
  font-weight: 700;
}
.w800 {
  font-weight: 800;
}
.bold {
  font-weight: bold;
}
/*边框*/

.bte {
  border-top: 1px solid #eee;
}

.bbe {
  border-bottom: solid 1px #eee;
}

.bre {
  border-right: 1px solid #eee;
}

.ble {
  border-left: 1px solid #eee;
}

.block {
  display: block;
}
.none {
  display: none;
}

.inline {
  display: inline-block;
}
.middle{
  vertical-align: middle;
}
.footfixed {
  display: block;
  position: fixed;
  bottom: 0;
  left: 0;
  background: #fff;
  z-index: 100;
  width: 100%;
  font-size: 14px;
  border-top: 1px solid #eee;
  line-height: 50px;
}

.lineNumber-2 {
  -webkit-line-clamp: 2;
}

.line-clamp {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}

.text-d {
  text-decoration: line-through;
}

.flex {
  display: flex;
}

.alignCenter {
  align-items: center;
}
.j-center {
  justify-content: center;
}
.line {
  height: 1px;
  background: #f5f5f5;
}
.h-shadow {
  box-shadow: 0px 1px 2px 3px #efefef;
}
.shadow-page {
  width: 96%;
  padding: 0px 2%;
}
.shadow-box {
  border-radius: 5px;
  box-shadow: 0px 0px 5px #ccc;
}
.contain {
  object-fit: contain;
}
.cover {
  object-fit: cover;
}
.fill {
  object-fit: fill;
}

.y-1 {
  transform: translateY(-1px);
}
.y-2 {
  transform: translateY(-2px);
}

.hidden {
  overflow: hidden;
}
.margin-center {
  margin: 0 auto;
}