@import '../../assets/css/variable.scss';

.cell {
    margin-top: 12px;
    padding: 10px;
    display: flex;
    align-items: center;
    background-color: #fff
}
.lefttext {
    color: #999;
    font-size: $font14;
}
.img {
    margin-right: 10px;
    width: 25px;
}