.close_img {
  width: 22px;
  height: 22px;
  object-fit: contain;
}

.input {
  background-color: #f5f5f5;
  height: 47px;
  border-radius: 25px;
  padding: 0;
  font-size: 14px;

  input:-webkit-autofill {
    -webkit-text-fill-color: #2A3F54 !important;
    box-shadow: 0 0 0px 1000px transparent inset !important;
    background-color: transparent;
    background-image: none;
    transition: background-color 50000s ease-in-out 0s;
  }

  input {
    color: #333 !important;
    font-size: 16px !important;
    letter-spacing: .5px;
    padding: 10px 0 !important;
  }
}

.button {
  border-radius: 25px;
}

.wechatLogin {
  margin-top: 40%;
  background-color: #09bb07;
  width: 80%;
  height: 50px;
  line-height: 50px;
  color: white;
  border-radius: 25px;
  font-size: 18px;
  cursor: pointer;
}

.bottom {
  position: absolute;
  width: 80%;
  bottom: 40px;
  left: 10%;
}

.line {
  background-color: #eaeaea;
  height: 1px;
  flex: 3;
}

.text {
  color: #b1b1b1;
  margin: 0 10px;
  font-size: 13px;
}

.bottomImage {
  width: 40px;
  height: 40px;
  object-fit: contain;
}

.bottomText {
  font-size: 13px;
  color: #b1b1bb;
  margin-top: 5px;
}
