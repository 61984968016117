@import '../../assets//css/variable.scss';

.header {
  background-color: #6fc47c;
  padding-top: 45px;
  color: "#fff";
  height: 100px;
}
.image {
  width: 30px;
  height: 30px;
  margin-right: 5px;
  object-fit: cover;
}
.text {
  color: #fff;
  font-size: $font16;
  font-weight: 500;
}
.block_yellow {
  align-items: center;
  justify-content: center;
}
.small_text {
  color: #fff;
  font-size: $font12;
}
