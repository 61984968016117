@import '../../assets/css/variable.scss';

.container {
  background: #f9f9f9;
  position: relative;
  font-weight: 400;
  height: 100%;

  header {
    background: #ed715a;
    .login {
      padding-bottom: 20px;
      height: 160px;

      span {
        margin-top: 20px;
        color: #fff;
        font-size: $font18;
      }

      .line {
        width: 1.5px;
        margin-left: 5px;
        margin-right: 5px;
        height: $font16;
        background: #fff;
      }
    }
  }
}

.rowImage {
  width: 25px;
  height: 28px;
  object-fit: contain;
}

.rowWrap {
  background-color: #eaeaea;
  width: 1px;
  height: 40px;
  margin-top: 5px;
}

.left_ico {
  width: 20px;
  height: 20px;
}

.right_ico {
  width: 20px;
  height: 20px;
}

.cell {
  padding: 10px;
  background: #fff;
  height: 42px;
}

.avatar {
  justify-content: center;
  align-items: center;
  height: 160px;
  display: flex;
  padding: 20px 0;
  position: relative;
  .logout{
    position: absolute;
    top: 20px;
    right: 20px;
    color: #fff;
    font-size: 22px;
  }
}

.avatarView {
  justify-content: center;
  align-items: center;
  height: 160px;
  display: flex;
  flex-direction: column;
}

.avatarImage {
  width: 64px;
  height: 64px;
  border-radius: 32px;
  margin-top: 20px;
}

.avatarText {
  margin-top: 10px;
  color: #fff;
  font-size: $font18;
  background-color: rgba(0, 0, 0, 0);
}
.fix{
  // transition: .3s ease-in-out;
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  top: 0;
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  img {
    width: 70%;
  }
div{
  width: 80%;
  text-align: right;
  i {
    font-size: 28px;
    color: #fff;
  }
}
}