.bbsArticleMain {
  margin: 10px 0;
  line-height: 1.7;
  color: #333;
  font-size: 16px;
  word-break: break-all;
  p {
    margin-top: 7px;
  }
  img {
    border-radius: 5px;
    // margin-top: 10px;
    // display: block;
  }
}