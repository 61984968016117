@import "../../assets//css/variable.scss";

$height: 80px;
.itemContainer {
  padding-top: 12px;
  padding-bottom: 12px;
  display: flex;
}
.left {
  width: 35%;
  border-radius: 3px;
  overflow: hidden;
  position: relative;
  img {
    width: 100%;
    height: $height;
    border-radius: 3px;
    overflow: hidden;
    object-fit: cover;
  }
}
.itemText {
  color: #999999;
  font-size: $font12;
}
.align {
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
  margin-right: 5px;
}
.image {
  width: $font14;
  height: $font14;
  margin-left: 2px;
  margin-top: 2px;
}
.title {
  position: absolute;
  z-index: 10;
  background-color: #ee7e5f;
  padding: 0 5px;
  top: 0;
  left: 0;
  color: #fff;
  font-size: $font12;
}
.right {
  width: 65%;
  height: $height;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-left: 12px;
  & > div {
    width: 100%;
  }
}
