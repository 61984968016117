@import '../../assets/css/variable.scss';

.titleText {
  font-size: 22px;
  color: $c3;
  font-weight: 500;
  letter-spacing: .5px;
}
.titleImage {
  margin-left: 8px;
  height: 17px;
  width: 17px;
  object-fit: contain;
}