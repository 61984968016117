.articleMain {
  margin: 10px 0;
  line-height: 1.7;
  color: #333;
  font-size: 17px;
  p {
    margin-top: 7px;
    font-size: 17px;
    word-break: break-word;
  }
  h1 {
    font-size: 16px;
    font-weight: bold;
    margin: 0.67em 0;
}
  .small-mark {
    color: #666;
    font-size: 12px;
    font-weight: normal;
  }

  .predictor {
    border-radius: 5px;
    padding: 12px;
    background-color: #faeadb;
    border:1px solid #a39480;
    position: relative;
    .flex {
      display: flex;
    }

    $red: #ef8662;
    $green: #86b65f;
    $blue: #61a9f8;
    $gray: #3c3c3c;
    $white: #fff;

    .red.active {
      background-color: $red;
      color: $white;
    }

    .red {
      color: $red;
      // border: 1px solid $red;
    }

    .green.active {
      background-color: $green;
      color: $white;
    }

    .green {
      color: $green;
      // border: 1px solid $green;
    }

    .blue.active {
      background-color: $blue;
      color: $white;
    }

    .blue {
      color: $blue;
      // border: 1px solid $blue;
    }

    .gray {
      color: $gray;
      // border: 1px solid $gray;
    }

    .tag {
      margin-top: 6px;
      margin-left: 1.14%;
      margin-right: 1.14%;
      font-size: 10px;
      border-radius: 15px;
      text-align: center;
      display: inline-block;
      width: 29%;
      height: 21px;
      line-height: 22px;
      background-color: white;
    }

    .item {
      .header {
        display: flex;
        align-items: center;
        justify-content: center;
        .vs-center {
          text-align: center;
          width: auto;
          line-height: 1.5;
          font-size: 12px;

          .vs-title {
            font-weight: 500;
            font-size: 14px;
            color: #333;
          }

          >span {
            display: block;
          }
        }

        img {
          width: 3 * 12px;
          height: 3 * 12px;
          object-fit: contain;
        }
      }

      .predictor-content {
        margin: 0 2em;
        margin-top: 6px;

        .rq {
          position: absolute;
          width: 27px;
        }

        .flex {
          justify-content: flex-end;
          margin-left: 32px;
        }

        .oi {
          font-size: 24px;
          position: absolute;
          color: red;
          left: -25px;
        }
      }
    }
  }


  .match {
    text-align: center;
    margin: 0 auto;
    border: 1px solid rgba(136, 136, 136, 0.55);
    height: 1.5rem;

    tr {
      height: 20px;
      padding: 0 auto;
      border: 1px 1px solid rgba(136, 136, 136, 0.55) !important;
    }

    td {
      border: 1px solid rgba(136, 136, 136, 0.55) !important;
      padding: .2rem;
    }
  }

  .suggest {
    background-color: #f9dd19;
  }

  .match-wamp {
    width: 70%;
    margin-left: .7rem;
    padding: .2rem;
    height: 2.5rem;
    border: 1px dashed #3c8596;
  }

  .match-item {
    text-align: center;
    margin: 0 auto;
    margin-bottom: 5px;
  }

  .article-content {
    img:not(.logo) {
      width: 100% !important;
      height: 100% !important;
    }

    p {
      margin-bottom: 0px !important;
    }
  }

  .first-wrap {
    margin: 0 15px;

    .first-img {
      width: 100%;
      height: 100%;
      margin-top: .2rem;
      margin-bottom: .1rem;
      -webkit-border-radius: 4px;
      -moz-border-radius: 4px;
      border-radius: 4px;
    }

    .result-arrange {
      font-size: 12px;
      color: #666;
      margin: 5px auto 10px 10px;

      ul.clink {
        color: white;
        display: inline-block;
        vertical-align: middle;
        margin-bottom: 10px;

        li {
          margin-top: 5px;
          list-style-type: none;
          float: left;

          i {
            margin: 0 10px;
            display: inline-block;
            color: #aaa;
            font-weight: bold;
          }

          span {
            display: inline-block;
            padding: 5px 10px;
            text-align: center;
            border-radius: 3px;
          }
        }
      }
    }
  }
}