.row_line {
  flex: 3;
  height: 1px;
  background-color: #eaeaea;
}

.avatar {
  width: 38px;
  height: 38px;
  border-radius: 18px;
}

.icon_msg {
  width: 19px;
  height: 19px;
  object-fit: contain;
  margin-right: 10px;
  margin-top: 1px;
}

.ico_fabulous {
  width: 15px;
  height: 15px;
  object-fit: contain;
  margin-right: 3px;
}

.footer {
  position: absolute;
  width: 100%;
  padding: 0 5%;
  bottom: 0;
  left: 0;
  background-color: #fff;
  height: 44px;
  border-top: 1px solid #eaeaea;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  justify-content: space-between;
  input {
    flex: 7.5;
    border: 1px solid #eaeaea;
    border-radius: 20px;
    padding: 0 10px;
    font-size: 12px;
    outline: none;
    background-color: #f4f4f4;
    height: 30px;
    &:focus {
      border: 1px solid #c6c6c6;
    }
  }
  img{
    flex: 1.5;
    width: 20px;
    height: 20px;
    object-fit: contain;
  }
  // div {
  //   flex: 1.5;
  //   font-size: 14px;
  //   color: #333;
  //   text-align: center;
  // }
}
.wrapComment {
  background-color: #eee;
  border-radius: 5px;
  padding: 5px;
  margin: 5px 0;
}