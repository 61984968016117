.shire {
    background: #fff;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .mask {
      width: 100%;
      height: 100%;
      position: fixed;
      top: 0;
      left: 0;
      z-index: 99;
      background: url('/images/tobrowser.png') no-repeat transparent;
      background-color: rgba(0,0,0,.7);
      background-size: 80%;
      background-position-x: right;
      background-position-y: top;
    }
    header {
      width: 100vw;
      height: 50vh;
      background-image: url("/images/yq-1.png");
      background-size: 100% 100%;
      img {
        width: 80vw;
        margin: 24px auto 0;
        display: block;
      }
    }
    .tip {
        margin-top: 30px;
        width: 100%;
        display: inline-block;
        text-align: center;
        p {
          color: #fff;
          letter-spacing: 1.5px;
          line-height: 24px;
          padding: 0 10vw;
        }
      }
    section {
      display: flex;
      -ms-flex-direction: column;
      flex-direction: column;
      margin: 0px 10vw;
      width: 80vw;
      margin-bottom: 6vh;
      input {
        margin-top: 10px;
        text-align: center;
        border: 1px solid #eaeaea;
        border-radius: 20px;
        height: 40px;
        font-size: 15px;
        outline: none;
        &:focus {
          border: 1px solid #ff592cc2;
        }
      }
      button,
      a {
        text-align: center;
        border: 1px solid #eaeaea;
        background: #ff592c;
        border-radius: 20px;
        height: 40px;
        font-size: 15px;
        margin-top: 10px;
        color: #fff;
        outline: none;
        text-decoration: none;
        &:active {
          opacity: 0.7;
        }
      }
    }
    footer {
      text-align: center;
      margin-bottom: 1vh;
      // position: absolute;
      // bottom: 30px;
      // left: 0;
      width: 100%;
      .ad {
        font-size: 15px;
        color: #666;
        margin-bottom: 20px;
      }
      .cell {
        display: flex;
        justify-content: space-around;
        padding: 0 10vw;
        .cell_main {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          img {
            width: 10vw;
            border-radius: 50%;
            overflow: hidden;
          }
          .cell_nick {
            color: #666;
            font-size: 14px;
            margin-top: 7px;
          }
          .cell_grade {
            color: #ff592c;
            margin-top: 3px;
            font-size: 15px;
            font-weight: bold;
          }
        }
      }
      .font{
       img{
         width: 100%;
       }
      }
    }
  }