@import '../../assets//css/variable.scss';

.wrap {
  text-align: center;
  position: relative;
  width: 100%;
  height: 20px;
  line-height: 20px;
  color: #fff;
  border-radius: 10px;
  font-size: $font14;
  background: #5fc47c;

  i {
    position: absolute;
    right: 5px;
  }
}

.item {
  font-size: $font13;
  color: #666;
}

.result {
  border:1px solid #e3e3e3;
  padding: 5px 8px;
  border-radius: 15px;
  width: 30%;
  margin-top: 8px;
  text-align: center;
}

.s_result {
  border-width: 0;
  background-color: #5fc47c;
}

.text {
  font-size: $font12;
  color: #464646;
  text-align: center;
}

.s_text {
  color: #fff;
  font-size: $font12;
}
.result2 {
  border:1px solid #5ca7f6;
  padding: 5px 8px;
  border-radius: 15px;
  width: 46%;
  margin-top: 8px;
  text-align: center;
}

.s_result2 {
  border-width: 0;
  background-color: #5ca7f6;
}

.text2 {
  font-size: $font10;
  color: #4ca7f6;
  text-align: center;
}

.s_text2 {
  color: #fff;
  font-size: $font10;
}

.wrapCell {
  padding: 10px 20px;
}
.drawer {
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
  overflow: hidden;
  .bg {
    background: rgba(0,0,0,0.5);
    position: absolute;
    width: 100%;
    height: 100%;
  }
}
$FOOTWE_WHEIGHT: 50PX;
.drawerContent{
  padding-top: 44px;
  background: #fff;
  width: 90%;
  margin-left: 10%;
  position: relative;
  height: calc(100vh - 50px);
  overflow: auto;
}

.drawerFooter {
  border-top: 1px solid #eaeaea;
  position: absolute;
  bottom: 0;
  left: 0;
  background: #fff;
  height: $FOOTWE_WHEIGHT;
  box-sizing: border-box;
  width: 90%;
  margin-left: 10%;
}