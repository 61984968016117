html, body, p, ol, ul, li, dl, dt, dd, blockquote, figure, fieldset, legend, textarea, pre, iframe, hr, h1, h2, h3, h4, h5, h6 {
    margin:0;
    padding:0;
}
h1, h2, h3, h4, h5, h6 {
    // font-size:100%;
    // font-weight:normal
}
ul {
    list-style:none
}
button, input, select, textarea {
    margin:0
}
html {
    box-sizing:border-box
}
* {
    box-sizing:inherit
}
*:before, *:after {
    box-sizing:inherit
}
img, embed, object, audio, video {
    height:auto;
    max-width:100%
}
iframe {
    border:0
}
table {
    border-collapse:collapse;
    border-spacing:0
}
td, th {
    padding:0;
    text-align:left
}
input {
    -webkit-appearance: none;
}

.am-tabs-default-bar-top .am-tabs-default-bar-tab::after {
    height: 0 !important;
}
.am-tab-bar-bar{
  z-index: 0 !important;
}
.am-list-line::after, .am-list-body::before{
    background-color: #eaeaea !important;
}
.am-tabs-default-bar-prevpage, .am-tabs-default-bar-nextpage {
  background: none !important;
}

.am-list-item .am-input-label.am-input-label-5 {
  width: auto !important;
}
.am-popover-inner-wrapper {
  max-height: 300px !important;
  overflow: auto !important;
}