.input {
  margin-top: 10px;
  padding: 0;
  font-size: 14px;
  line-height: 44px;
  .am-list-item .am-input-label {
    width: 60px !important;
    color: red;
  }
  input:-webkit-autofill {
    -webkit-text-fill-color: #2A3F54 !important;
    box-shadow: 0 0 0px 1000px transparent inset !important;
    background-color: transparent;
    background-image: none;
    transition: background-color 50000s ease-in-out 0s;
  }

  input {
    color: #333 !important;
    font-size: 14px !important;
    letter-spacing: .5px;
    padding: 10px 0 !important;
  }
  .am-list-line::after, .am-list-body::before {
    background-color: red !important;
}
}