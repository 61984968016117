@import '../../../assets/css/variable.scss';

.title {
  margin-top: 10px;
}

.titleText {
  color: #333;
  font-weight: bold;
  letter-spacing: 1px;
  font-size: $font25;
}

.title_avator {
  width: 36px;
  height: 36px;
  border-radius: 18px;
  margin-right: 8px;
}

.auto {
  flex: 1;
}

.title_left {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.title_name {
  font-weight: 600;
  color: #333;
  letter-spacing: .5px;
  font-size: $font14;
  height: 20px;
  line-height: 20px;
}

.title_g_f {
  background-color: #fff;
  display: flex;
  align-items: center;
  border-radius: 10px;
  border: 1px solid #ff5d47;
  padding: 2px 8px;
  margin-left: 7px;
  height: 20px;
}

.title_guanzhu {
  font-size: $font11;
  margin-left: 2px;
}

.title_yuedu {
  font-size: $font11;
  color: #999;
  margin-left: 10px;
  height: 20px;
  line-height: 20px;
  font-weight: 600;
}

.title_bottom {
  margin-top: 1px;
  height: 20px;
  line-height: 20px;
}

.title_bottom_text {
  font-size: $font10;
  color: #999;
  margin-right: 15px;
}

.title_jubao {
  font-size: $font12;
  color: #999;
  margin-left: 2px;
}

.main_data {
  background-color: #5cbf69;
  border-radius: 8px;
  margin-top: 10px;
  position: relative;
  padding-top: 12px;
}

.position {
  width: 70px;
  height: 70px;
  position: absolute;
  right: 10px;
  z-index: 100;
  top: 10px;
}

.main_data_position {
  position: absolute;
  top: 15;
  left: 0;
  background-color: #fff;
  padding: 3px 7px;
  border-top-right-radius: 12px;
  border-bottom-right-radius: 12px;
}

.main_p_text {
  color: #666666;
  font-size: $font13;
}

.main_data_country_text {
  color: #fff;
  line-height: 20px;
  font-size: 15px;
  letter-spacing: 0.5px;
  font-weight: 600;
  margin-left: 5px;
}

.vs2 {
  color: #fff;
  font-size: $font14;
  transform: scale(1.5) translateY(-2px);
  margin: 0 15px;
}

.info_detail {
  display: flex;
  padding: 15px 15%;
  justify-content: flex-start;
}

.main_info_wrap {
  background-color: #fff;
  padding: 3px 10px;
  border-radius: 11px;
  text-align: center;
}

.main_info_text_one {
  font-size: $font13;
  color: #ff5d47;
  min-width: 40px;
  text-align: center;
}

.main_info_text_two {
  font-size: 13px;
  color: #64c371;
  min-width: 40px;
  text-align: center；
}

.reward_wrap {
  margin-top: 10px;
}
.reward_top_left {
  color: #444444;
  font-size: $font13;
}
.reward_top_image {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  margin-left: 5px;
}
.send_text {
  font-size: $font13;
  color: #444444;
  margin-left: 3px;
}
.reward_top_border {
  background-color: #fff;
  display: flex;
  align-items: center;
  border-radius: 10px;
  border: 1px solid $color-primary;
  padding: .5px 5px;
}
.reward_top_border_text {
  font-size: $font10;
  color: $color-primary,
}
.reward_bottom_text {
  font-size: $font11;
  color: #666666;
  margin-left: 3px;
}

// footer
.footer_tab {
  margin-right: 15px;
  border: 1px solid #999;
  border-radius: 10px;
  padding: 3px 10px;
  line-height: $font12;
}
.select_tab {
  border-color: #ff5d47;
}
.footer_tab_text {
  color: #999999;
  font-size: $font11;
}
.select_tab_text {
  color: #ff5d47;
}

.gift_wrap{
  padding: 10px 20px;
}
.gift_card_wrap{
  width:25%;
  text-align: center;
  line-height: 100%;
}
.gift_card_img {
  width: 70%;
  margin-top: 15px;
}
.gift_card_text {
  color: #333333;
  font-size: $font11;
  margin-top: 3px;
}
.footer {
  position: absolute;
  width: 100%;
  padding: 0 15px;
  // bottom: 10px;
  top: calc(100% - 44px);
  left: 0;
  background-color: #fff;
  height: 44px;
  border-top: 1px solid #eaeaea;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  justify-content: space-between;
  input {
    flex: 1;
    border: 1px solid #eaeaea;
    border-radius: 20px;
    padding: 0 10px;
    font-size: 12px;
    outline: none;
    background-color: #f4f4f4;
    height: 30px;
    &:focus {
      border: 1px solid #c6c6c6;
    }
  }
  img{
    margin-left: 10px;
    width: 20px;
    height: 20px;
    object-fit: contain;
  }
  .button{
    margin-left: 10px;
    width: 80px;
  }
  // div {
  //   flex: 1.5;
  //   font-size: 14px;
  //   color: #333;
  //   text-align: center;
  // }
}

.row_line {
  flex: 3;
  height: 1px;
  background-color: #eaeaea;
}

.avatar {
  width: 38px;
  height: 38px;
  border-radius: 18px;
}

.icon_msg {
  width: 19px;
  height: 19px;
  object-fit: contain;
  margin-right: 10px;
  margin-top: 1px;
}

.ico_fabulous {
  width: 15px;
  height: 15px;
  object-fit: contain;
  margin-right: 3px;
}
.wrapComment {
  background-color: #eee;
  border-radius: 5px;
  padding: 5px;
  margin: 5px 0;
}