$color: #ed715a;
.hay0816{
    display: flex;
    height: 24px;
    overflow: hidden;
    width: 100px;
    border-radius: 20px;
    border: 1px solid $color;
    box-sizing: border-box;
    .hay0816-item {
        font-size: 10px;
        line-height: 23px;
        color: $color;
        background: #fff;
        width: 50%;
        text-align: center;
    }
    .hay0816-item-selected {
        background: $color;
    }
}