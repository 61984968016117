@import "../../assets/css/variable.scss";

.header {
  background: #ed715a;
  padding-left: 28px;
  padding-top: 10px;
  padding-bottom: 10px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  margin-top: 10px;
}
.image {
  width: 34px;
  height: 20px;
  object-fit: cover;
}
.header_time {
  background: #fff;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  margin-top: 3px;
  padding-inline-start: 13px;
  padding-right: 5px;
}
.header_num {
  border: 1px solid #fff;
  padding: 1px 7px;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
}
.tab {
  width: 70px;
  margin-left: 10px;
  border-radius: 20px;
  padding: .5px;
  overflow: hidden;
  background: #fff;
  span {
    text-align: center;
    padding: 3px;
  }
  span:nth-child(2) {
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
    overflow: hidden;
    background: #ed715a;
  }
}
.resultImage {
  position: absolute;
  right: 10px;
  top: 20px;
  width: 44px;
  height: 44px;
  object-fit: cover;
}
.main_line {
  display: flex;
  padding-left: 28px;
  margin-top: 10px;
}
.main_title {
  font-size: $font13;
  color: #a9b0b1;
}
.main_result {
  font-size: $font13;
  color: #333e4a;
  margin-left: 10px;
}
.reason {
  background-color: #f2f2f2;
  margin: 15px 0;
  padding: 12px;
  border-radius: 8px;
}
.reason_text {
  font-size: $font13;
  color: #acacbe;
}
