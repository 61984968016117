@import '../../assets//css/variable.scss';

.container {
  position: relative;
  background-color: #fff;
}
.image {
  width: 28px;
  height: 20px;
  object-fit: contain;
}
.bifen{
    color: #ff7575;
    font-size: $font16;
    font-weight: 700;
    margin: 0 5px;
}
.circle{
    width: $font20;
    height: $font20;
    position: absolute;
    right: 10px;
    top: 30px;
    animation-name: rotate;
    animation-timing-function: ease-in-out;
    animation-duration: 1.5s;
    animation-delay: .5s;
    animation-iteration-count: infinite;
}
@keyframes rotate {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}