$base: 1;
$font10: 10*$base + px;
$font11: 11px;
$font12: 12*$base + px;
$font13: 13px;
$font14: 14px;
$font15: 15px;
$font16: 16px;
$font17: 17px;
$font18: 18px;
$font19: 19px;
$font20: 20px;
$font21: 21px;
$font22: 22px;
$font24: 24px;
$font25: 25px;

$c3: #333;
$c6: #666;
$c9: #999;
$color-primary: #ed715a;
$color-blue: #7594ca;