@import "../../assets/css/variable.scss";
.img {
    border-radius: 3px;
    width: 18vw;
    height: 16vw;
    // transform: scaleY(0.95);
    object-fit: cover;
}
.title {
  float: left;
  color: $c3;
  font-size: $font14;
  font-weight: 600;
}
.join {
  float: right;
  background-color: #ff9800;
  color: #fff;
  font-size: $font12;
  padding: 3px 10px;
  border-radius: 10px;
}
.hasJoin {
  float: right;
  background-color: #ccc;
  color: #fff;
  font-size: $font12;
  padding: 3px 10px;
  border-radius: 10px;
}
.text {
  clear: both;
  p {
    padding-top: 5px;
    color: $c9;
    letter-spacing: 0.5px;
    font-size: $font13;
    line-height: 20px;
  }
}
.status {
  position: absolute;
  right: 10px;
  bottom: $font12;
  text-align: right;
  font-size: $font12;
  color: $c9;
}


$fixwidth: 40px;
.fix {
  right: 5px;
  bottom: 20px;
  color: #f50;
  position: absolute;
  z-index: 100;
  div {
    background-color: #fff;
    width: $fixwidth;
    height: $fixwidth;
    text-align: center;
    border-radius: 50%;
    box-shadow: 0px 2px 1px #ccc;
    margin-bottom: 7px;
    border: 1px solid #f4f4f4;
    i {
      line-height: $fixwidth;
      font-size: 22px;
    }
  }
}