@import "../../assets/css/variable.scss";

.avatar {
  width: 40px;
  border-radius: 50%;
  vertical-align: middle;
}

.button {
  padding: 3px 10px;
  background-color: $color-primary;
  color: #fff;
  font-size: $font11;
  border-radius: 10px;
  float: right;
  margin-top: 5px;
}

.isButton {
  padding: 3px 10px;
  border: 1px solid $color-primary;
  color: $color-primary;
  font-size: $font11;
  border-radius: 10px;
  float: right;
  margin-top: 5px;
}

.main {
  margin-top: 5px;
  -webkit-line-clamp: 3;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  line-height: 20px;
  color: #555;
  letter-spacing: .3px;
  font-size: $font15;
  word-break: break-word;
  line-height: 24px;
}

.title {
  // margin-right: 10px;
  letter-spacing: .5px;
  font-weight: 600;
  color: #000;
}

.badge {
  color: #4d8090;
  font-size: $font14;
  margin-right: 8px;
  // position: absolute;
  // left: 0;
  // top: 7px;
}

.info {
  transform: translateY(6px);
  // margin-top: 5px;
  // height: 30px;
  // margin-left: 40px;
}

.cell_middle_img {
  width: 24px;
  height: 18px;
  object-fit: cover;
}

.country {
  color: #4d8090;
  font-size: $font14;
}

.vs {
  color: #4d8090;
  font-size: $font18;
  margin: 0 3px;
}

.carousel {
  height: 30px;
}

.huifu {
  display: inline-block;
  white-space: nowrap;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  letter-spacing: .5px;
  font-size: $font14;
  color: $c9;
  height: 30px;
  line-height: 30px;
}

.icon {
  width: 30px;
  height: 16px;
  object-fit: contain;
  margin-left: 10px;
}

.position {
  width: 70px;
  height: 70px;
  position: absolute;
  right: 50px;
  z-index: 100;
  top: 20px;
}