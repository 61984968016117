@import '../../assets/css/variable.scss';


.avatar {
  background-color: $color-primary;
  padding: 10px 20px;
  transform: translateY(-1px);
  .avatarImage {
    width: 50px;
    border-radius: 50%;
    border: .5px solid #fff;
  }
  .grade {
    background-color: #e84732;
    padding: 4px 10px;
    border-radius: 10px;
    font-size: $font11;
    color: #fff;
  }
}

.renderRow{
  margin-top: 12px;
  background-color: #fff;
  padding: 10px 0;
  margin-bottom: 15px;
  div {
    text-align: center;
    flex:1;
    p:nth-child(1) {
      color: #ff4e44;
      font-size: $font15;
      font-weight: 400
    }
    p:nth-child(2) {
      color: #ababab;
      font-size: $font12;
      margin-top: 5px;
    }
  }
}
.tab_text{
  font-size: $font13;
  color: #555;
  margin-right: 10px;
  line-height: 22px;
  box-sizing: border-box;
  height: 22px;
}
.tab_s_text{
  @extend .tab_text;
  color: #ff4e44;
  border-bottom: 1.5px solid #ff4e44;
}