@import "../../assets/css/variable.scss";

$imgSize: 70%;
.top_left_img {
  width: $imgSize;
  height: $imgSize;
  border-radius: $imgSize;
  margin-top: 3px;
}
.top_center {
  width: 55%;
  margin-top: 5px;
}
.top_center_bg {
  background-color: #ed715a;
  padding: 2px 8px;
  margin-left: 5px;
  border-radius: 10px;
  line-height: 100%;
}
.top_center_text {
  font-size: $font10;
  color: #ed715ab5;
  margin: 3px 5px 0 0;
  padding: 1px 3px;
  border-radius: 5px;
  // border: 1px solid #ed715ab5;
}
.top_right_scare {
  color: #ed715a;
  margin-left: 10px;
  letter-spacing: 0.5px;
}
.bg {
  background-color: #f2f2f2;
  border-radius: 5px;
  padding: 10px 5px;
  margin-top: 10px;
  position: relative;
}
.icon {
  width: 26px;
  height: 18px;
  margin-left: 5px;
  object-fit: contain;
}
.resultImage{
    position: absolute;
    right: 27%;
    bottom: 3px;
    z-index: 10;
    width: 44px;
    height: 44px;
    object-fit: cover;
}
.buttonWrap {
    position: absolute;
    bottom: 5px;
    right: 3px;
}
.btn {
    padding: 0 10px;
    height: 24px;
    line-height: 24px;
    background-color: #67c774;
    border-radius: 20px;
    color: #fff;
    text-align: center;
    font-size: $font11;
}
.img2 {
  width: 18px;
  height: 18px;
  object-fit: contain;
}