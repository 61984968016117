.Notify {
  background: #ff976a;
  text-align: center;
  height: 30px;
  animation: slideDown .7s ease-in-out;
  line-height: 30px;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}
.Notify_F {
  position: absolute;
  width: 100%;
  z-index: 100;
}
@keyframes slideDown {
  from {
    height: 0;
  }
  to {
    height: 30px;
  }
}