.avatar{
  padding-bottom: 12px;
  background: #ed715a;
  position: absolute;
  top: 44px;
  left: 0;
  z-index: 10;
  width: 100%;
  height: 80px;
}
.avatarImage{
  width: 50px;
  height: 50px;
  border-radius: 25px;
  border: .5px solid #fff;
}
.avatar_right{
  height: 50px;
  justify-content: space-between;
  display: flex;
  flex-direction: column;
  margin-left: 10px;
}
.grade{
     background-color: #e84732;
     padding: 4px 10px;
    border-radius: 10px;
}