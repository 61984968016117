@import '../../assets/css/variable.scss';

.linear {
    background-image: linear-gradient(#ef8986, #ee7672, #ec625d);
    border-radius: 6px;
    height: 150px;
}
.title {
    font-size: $font22;
    font-weight: bold;
    color: #fff;
}
.smallTitle {
    color: #fffefe;
    margin-top: 12px;
    line-height: 18px;
    font-size: $font12;
}
.bottom {
   display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 18px;
}
.button {
    background-color: #fff;
    padding: 8px 28px;
    border-radius: 20px;
}
.wrapTitle {
    display: flex;
    align-items: center;
    padding-top: 20px;
}
.titleImage {
    width: 26px;
    object-fit: cover;
}
.linearGreen {
    @extend .linear;
    background-image: linear-gradient(#94d258, #84d258);
}
.linearYellow {
    @extend .linear;
    background-image: linear-gradient(#f6c37e, #f08d54);
}
.wrap {
    padding: 0 12px;
    background-color: #484d59;
    height: 130px;
}
.c_linear {
    @extend .linear;
    height: 140px;
}
.forum {
    height: 140px;
    background-color: #ed715a;
}